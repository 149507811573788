import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../Components/Sidebar/Sidebar";
import useAccountInfo from "../../hooks/useAccountInfo";
import CalendarPage from "./Calendar/CalendarPage";
import Completed from "./Completed/Completed";
import Locations from "./Locations/Locations";
import Schedule from "./Schedule/Schedule";
import Users from "./Users/Users";

import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import * as Sentry from "@sentry/react";
import useFeature from "../../hooks/useFeature";
import usePreferredLanguageStore from "../../stores/usePreferredLanguageStore";
import { Analytics } from "./Analytics/Analytics";
import { CalendarOnboardingPopup } from "./Calendar/components/CalendarOnboardingPopup";
import ChangeLog from "./ChangeLog/ChangeLog";
import { ClientSettings } from "./ClientSettings/ClientSettings";
import { LimeAdmin } from "./LimeAdmin/LimeAdmin";
import { NotificationSettings } from "./NotificationSettings/NotificationSettings";
import { ManageResource } from "./ResourceGroups/ManageResource";
import { ManageResourceGroup } from "./ResourceGroups/ManageResourceGroup";
import { ResourceGroups } from "./ResourceGroups/ResourceGroups";
import { GeneralTab as ResourceGroupGeneralTab } from "./ResourceGroups/Tabs/GeneralTab";
import { ResourcesTab as ResourceGroupResourcesTab } from "./ResourceGroups/Tabs/ResourcesTab";
import { ScheduleTab as ResourceGroupScheduleTab } from "./ResourceGroups/Tabs/ScheduleTab";
import { ServicesTab as ResourceGroupServicesTab } from "./ResourceGroups/Tabs/ServicesTab";
import { ManageCategory } from "./Services/ManageCategory";
import { ManageService } from "./Services/ManageService";
import { ServiceCategories } from "./Services/ServiceCategories";
import { Services } from "./Services/Services";
import { AccountSettings as StripeAccountSettings } from "./StripeSettings/AccountSettings";
import { GeneralTab as StripeGeneralTab } from "./StripeSettings/GeneralTab";
import { PaymentsOverviewTab as StripePaymentsOverviewTab } from "./StripeSettings/PaymentsOverviewTab";
import { ServicePaymentSettings as StripeServicePaymentSettings } from "./StripeSettings/ServicePaymentSettings";
import { Stripe } from "./StripeSettings/Stripe";
import { Integrations } from "./Integrations/Integrations";
import { FacebookConversionApi } from "./Integrations/FacebookConversionApi";

const MainContent = styled.div<{
  ignorePadding?: boolean;
}>`
  flex: 1;

  position: relative;

  z-index: 19;
  overflow: visible;

  background: white;

  //box-shadow: -2px 0 30px rgba(50, 67, 61, 0.08);

  min-height: 100vh;
  padding: ${(props) => !props.ignorePadding && "0 15px"};
  padding-bottom: 70px;

  width: 100%;

  @media (min-width: 768px) {
    overflow: scroll;
    /*padding: 0 50px;*/
    padding: ${(props) => !props.ignorePadding && "1rem 50px"};
    padding-bottom: 50px;
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */

    height: 100vh;
  }
`;

export const PageOuter = styled.div<{
  isMobile?: boolean;
  isLoading?: boolean;
}>`
  position: relative;
  //width: ${(props) => (props.isMobile ? "95%" : "100%")};
  margin: 0 auto;
  //padding-right: ${(props) => (props.isMobile ? "0" : "3rem")};
  margin-bottom: 2rem;
  ${(props) =>
    props.isLoading &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding-right: 0;
  `}
`;

export const Title = styled.label`
  font-size: 25px;
  text-align: left;
  display: block;

  color: #1a1c1a;
  transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);

  padding-top: 20px;
  font-weight: bold;

  padding-left: 70px;

  width: 70%;

  @media (min-width: 768px) {
    font-size: 35px;
    padding-left: 0;

    width: unset;
  }
  max-width: 70%;
`;

const Dashboard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showOnboardingPopup, setShowOnboardingPopup] = React.useState(false);

  const { loadPreferredLanguage } = usePreferredLanguageStore((state) => state);

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const location = useLocation();

  const { isFeatureEnabled } = useFeature();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const handleResize = () => {
    const width = window.innerWidth;
    setIsMobile(width < 768);
  };

  useEffect(() => {
    loadPreferredLanguage();
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!accountInfo?.onboardingDone) {
      setShowOnboardingPopup(true);
    }
  }, [accountInfo]);

  const ignoreContentPaddingPathNames = [
    "/dashboard/calendar",
    "/dashboard/resource-groups",
    "/dashboard/services",
    "/dashboard/notifications",
    "/dashboard/settings",
    "/dashboard/locations",
    "/dashboard/users",
    "/dashboard/integrations",
  ];
  const ignoreContentPadding = ignoreContentPaddingPathNames.filter((path) =>
    location.pathname.startsWith(path),
  );
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <div style={{ background: "#f2f7f5", minHeight: "100vh", display: "flex" }}>
      <Sidebar page={location.pathname} isMobile={isMobile} />

      {showOnboardingPopup && (
        <CalendarOnboardingPopup
          handleSkipOnboarding={() => setShowOnboardingPopup(false)}
        />
      )}

      <MainContent ignorePadding={ignoreContentPadding.length > 0}>
        <SentryRoutes>
          {permissions.view_settings_page && (
            <Route path="/settings" element={<ClientSettings />}></Route>
          )}
          {permissions.view_users_page && (
            <Route
              path="/users"
              element={<Users isMobile={isMobile}></Users>}
            ></Route>
          )}
          {permissions.view_analytics && (
            <Route path="/analytics" element={<Analytics></Analytics>}></Route>
          )}
          {permissions.view_locations_page && (
            <Route
              path="/locations"
              element={<Locations isMobile={isMobile}></Locations>}
            ></Route>
          )}
          {permissions.view_services_page && (
            <>
              <Route path="/services" element={<Services />} />
              <Route
                path="/services/categories"
                element={<ServiceCategories />}
              />

              <Route path="/services/:id" element={<ManageService />} />
              <Route
                path="/services/categories/:id"
                element={<ManageCategory />}
              />
            </>
          )}

          <Route
            path="/calendar"
            element={<CalendarPage></CalendarPage>}
          ></Route>
          {permissions.view_customer_list && (
            <Route path="/completed" element={<Completed />}></Route>
          )}
          {permissions.view_availability && (
            <Route path="/schedule" element={<Schedule></Schedule>}></Route>
          )}
          {permissions.view_notifications_page && (
            <Route
              path="/notifications/*"
              element={<NotificationSettings></NotificationSettings>}
            ></Route>
          )}
          {["admin", "salesman"].includes(accountInfo.systemRole) && (
            <Route path="/admin/*" element={<LimeAdmin></LimeAdmin>}></Route>
          )}
          {isFeatureEnabled("CHANGELOG") &&
            permissions.view_change_log_page && (
              <Route
                path="/change-log"
                element={<ChangeLog></ChangeLog>}
              ></Route>
            )}
          {isFeatureEnabled("PAYMENTS") && (
            <Route path="/stripe" element={<Stripe />}>
              <Route index element={<Navigate to="general" replace />} />
              <Route path="general" element={<StripeGeneralTab />} />
              <Route path="payments" element={<StripePaymentsOverviewTab />} />
              <Route path="settings" element={<StripeAccountSettings />} />
              <Route
                path="settings/services"
                element={<StripeServicePaymentSettings />}
              />
            </Route>
          )}
          {isFeatureEnabled("RESOURCES") && (
            <>
              <Route path="resource-groups" element={<ResourceGroups />} />
              <Route
                path="resource-groups/:id"
                element={<ManageResourceGroup />}
              >
                <Route index element={<Navigate to="general" replace />} />
                <Route path="general" element={<ResourceGroupGeneralTab />} />
                <Route path="services" element={<ResourceGroupServicesTab />} />
                <Route path="schedule" element={<ResourceGroupScheduleTab />} />
                <Route
                  path="resources/:resourceId"
                  element={<ManageResource />}
                />
                <Route
                  path="resources"
                  element={<ResourceGroupResourcesTab />}
                />
              </Route>
            </>
          )}
          {isFeatureEnabled("INTEGRATIONS") && (
            <>
              <Route path="integrations" element={<Integrations />}>
                <Route path="fbcapi" element={<FacebookConversionApi />} />
              </Route>
            </>
          )}
          <Route path={"*"} element={<Navigate to={"/dashboard/calendar"} />} />
        </SentryRoutes>
      </MainContent>
    </div>
  );
};

export default Dashboard;
