import dayjs from "dayjs";
import { create } from "zustand";
import { apiDeleteAppointment } from "../../../../Components/AppointmentModal/apiAppointment";
import { useAppointmentModalStore } from "../../../../Components/AppointmentModal/useAppointmentModalStore_";
import {
  apiGetCalendarData,
  apiUpdateCalendarFilter,
} from "../api/apiCalendar";
import "dayjs/locale/sl";
import { t } from "@lingui/macro";
import { notifications } from "@mantine/notifications";

export const useCalendarStore = create((set, get) => ({
  selectedDate: dayjs().locale("sl"),
  calendarView: null, // day, week, month

  fetchingCalendarData: false,
  isRefetchingCalendarDate: false,

  locations: [],
  users: [],
  services: [],
  serviceTags: [],
  timeoffs: [],
  resources: [],
  calendarMode: "resources",

  hasRescheduleNotification: false,

  selectedLocationId: null,

  filterItems: {
    location: null,
    users: [],
    services: [],
    days: [],
    resources: [],
  },

  lastSelectedUserId: null,
  lastSelectedTime: null,
  lastSelectedDate: null,
  lastSelectedResourceId: null,

  doesHaveCombinations: false,
  setDoesHaveCombinations: (doesHaveCombinations) =>
    set({ doesHaveCombinations }),

  hideModalTabs: false,
  setHideModalTabs: (hideModalTabs) => set({ hideModalTabs }),

  isExpandedModal: false,
  setIsExpandedModal: (isExpandedModal) => set({ isExpandedModal }),

  closeModals: () => {
    set({
      selectedAppointmentId: null,
      selectedBreakId: null,
      newBreakData: null,
      rescheduleData: null,
      hideModalTabs: false,
      isExpandedModal: false,
    });
    useAppointmentModalStore.getState().resetData();
    useAppointmentModalStore.getState().setCurrentAppointmentGsm(null);
    useAppointmentModalStore.setState({
      isCreatingAppointment: false,
    });

    get().refreshCalendar();
  },

  toggleCalendarMode: () => {
    if (get().calendarMode === "users") {
      set({ calendarMode: "resources" });
    } else {
      set({ calendarMode: "users" });
    }
  },

  setTimeoffs: (timeoffs) => set({ timeoffs }),

  deleteAppointment: async (appointmentId, delete_repeating, delete_linked) => {
    const response = await apiDeleteAppointment(
      appointmentId,
      delete_repeating,
      delete_linked,
    );

    if (response.status !== 200) {
      console.error("Error deleting appointment");
      return;
    }

    get().closeModals();
  },

  /* Quick shift break creation */
  newBreakData: null,
  setNewBreakData: (newBreakData) => set({ newBreakData }),

  /* PreScheduled appointment creation */
  newPreScheduledAppointmentData: null,
  setNewPreScheduledAppointmentData: (newPreScheduledAppointmentData) =>
    set({ newPreScheduledAppointmentData }),

  setLastSelectedTime: (time) => set({ lastSelectedTime: time }),
  setLastSelectedDate: (date) => set({ lastSelectedDate: date }),
  setLastSelectedUserId: (userId) => set({ lastSelectedUserId: userId }),
  setLastSelectedResourceId: (resourceId) =>
    set({ lastSelectedResourceId: resourceId }),

  rescheduleData: null,
  setRescheduleData: (rescheduleData) => set({ rescheduleData }),

  calendarData: [],
  setCalendarData: (calendarData) => set({ calendarData }),

  viewMode: "appointments",

  refreshWithNewFilter: async (filterItems) => {
    await apiUpdateCalendarFilter(get().selectedLocationId, filterItems).catch(
      (e) =>
        notifications.show({
          message: t`Nimate dovoljenja za urejanje filtra`,
          color: "red",
        }),
    );
    await get().refreshCalendar();
  },

  refreshCalendar: async (isRefetching = false) => {
    if (get().fetchingCalendarData) return;

    set({ fetchingCalendarData: true, isRefetchingCalendarDate: isRefetching });

    const calendarDataResponse = await apiGetCalendarData({
      locationId: get().selectedLocationId,
      date: get().selectedDate.format("YYYY-MM-DD"),
      view: get().calendarView,
    }).catch((err) => {});

    set({ fetchingCalendarData: false, isRefetchingCalendarDate: false });

    if (calendarDataResponse == null || calendarDataResponse?.status !== 200)
      return;

    console.log("calendarDataResponse", calendarDataResponse);

    const locations = calendarDataResponse.data.locations;
    get().locationActions.setLocations(locations);
    if (locations.length === 0) {
      console.error("No locations found.");
      return;
    }

    if (get().selectedLocationId == null) {
      get().locationActions.setSelectedLocationId(
        calendarDataResponse.data.filter?.FK_locationId ??
          locations[0].locationId,
      );
    }

    if (get().calendarView == null) {
      get().dateActions.setCalendarView(
        calendarDataResponse.data.calendarData.view,
      );
    }

    const users = calendarDataResponse.data.users;
    get().userActions.setUsers(users);

    const services = calendarDataResponse.data.services;
    get().serviceActions.setServices(services);

    const resources = calendarDataResponse.data.resources;
    get().resourceActions.setResources(resources);

    get().filterActions.setFilterItems(calendarDataResponse.data.filter);

    const calendarData = calendarDataResponse.data.calendarData;
    set({
      calendarData,
      serviceTags: calendarDataResponse.data.allTags,
      fetchingCalendarData: false,
      hasRescheduleNotification:
        calendarDataResponse.data.hasRescheduleNotification,
      calendarMode: calendarDataResponse.data.filter.calendarMode,
      doesHaveCombinations: calendarDataResponse.data.doesHaveCombinations,
    });
  },

  selectedAppointmentId: null, // -1 for new appointment
  setSelectedAppointmentId: (appointmentId) => {
    if (appointmentId == null) {
      get().refreshCalendar();
    }

    set({ selectedAppointmentId: appointmentId });
  },

  dateActions: {
    setSelectedDateAndView: (date, view) => {
      set({ selectedDate: date, calendarView: view });
      get().refreshCalendar();
    },

    setSelectedDate: (date, forceRefresh = false) => {
      const isSameDate = get().selectedDate.isSame(date, "day");

      set({ selectedDate: date });

      if (!isSameDate || forceRefresh) {
        get().refreshCalendar();
      }
    },
    setCalendarView: (view) => {
      const isSameView = get().calendarView === view;

      set({ calendarView: view });

      // only refresh calendar if not settings to same view
      if (!isSameView) {
        get().refreshCalendar();
      }
    },
  },
  locationActions: {
    setLocations: (locations) => set({ locations }),
    setSelectedLocationId: (id) => {
      set({ selectedLocationId: id });
    },
  },
  userActions: {
    setUsers: (users) => set({ users }),
  },
  resourceActions: {
    setResources: (resources) => set({ resources }),
  },
  serviceActions: {
    setServices: (services) => set({ services }),
  },
  filterActions: {
    setFilterItems: (filter) => {
      set({ filterItems: filter });
    },
  },
}));
