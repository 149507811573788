import { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Select,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { RiMapPin2Fill } from "react-icons/ri";
import { isValidPhoneNumber } from "react-phone-number-input";
import TimezoneSelect from "react-timezone-select";
import { fullPhoneNumberToNumberAndCountryCode } from "@/Utilities";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { LimeLoader } from "@/Components/LimeLoader";
import { LimePageHeader } from "@/Components/LimePageHeader";
import LimePhoneInputField from "@/Components/LimePhoneInputField";
import TimeSpanRow from "@/Components/TimeSpanRow";
import EmptyState from "../../../Components/EmptyState";
import LimeDrawer from "../../../Components/LimeDrawer/LimeDrawer";
import useAlertModal from "../../../Components/Modals/useAlertModal";
import LocationTable from "./LocationTable/LocationTable";
import useLocations from "./useLocations";
import useModalLocation from "./useModalLocation";
import LocalePicker from "../../../Components/LocalePicker";
import { countries } from "../../../../../shared/constants/countries";

const Locations = ({ isMobile }) => {
  useLingui();
  const {
    locations,
    addLocation,
    updateExistingLocation,
    removeLocation,
    isLoading,
    error,
    currencies,
  } = useLocations();

  const [
    fields,
    handleFieldChange,
    modalOpened,
    toggleModal,
    updateFields,
    updateLocation,
    handleStartTimeChange,
    handleEndTimeChange,
  ] = useModalLocation();

  const { alertModal } = useAlertModal();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  return (
    <>
      <LimePageHeader
        title={t`Lokacije`}
        rightSection={{
          label: t`Dodaj lokacijo`,
          onClick: () => {
            setHasSubmitted(false);
            toggleModal();
          },
          icon: <RiMapPin2Fill size={"1.3rem"} />,
        }}
      />

      {isLoading ? (
        <LimeLoader />
      ) : (
        <Box px={isMobile ? "sm" : "xl"} mt={"md"}>
          <LimeDrawer
            isMobile={isMobile}
            update={updateLocation}
            opened={modalOpened}
            closeHandle={toggleModal}
            onClose={modalOpened ? toggleModal : null}
            className="modal_location"
            title={updateLocation ? t`Uredi lokacijo` : t`Dodaj lokacijo`}
            buttons={{
              save: (
                <Button
                  onClick={() => {
                    setHasSubmitted(true);

                    if (
                      !fields.name ||
                      !fields.address ||
                      !fields.city ||
                      fields.name === "" ||
                      fields.address === "" ||
                      fields.city === "" ||
                      !fields.startTime ||
                      !fields.endTime ||
                      fields.startTime >= fields.endTime ||
                      fields.paymentCurrency === "" ||
                      !fields.paymentCurrency ||
                      (fields.gsm &&
                        fields.gsm.length > 0 &&
                        !isValidPhoneNumber(fields.gsm))
                    ) {
                      return;
                    }

                    let newFields = { ...fields };
                    if (fields.gsm && isValidPhoneNumber(fields.gsm)) {
                      const parsedNumber =
                        fullPhoneNumberToNumberAndCountryCode(fields.gsm);
                      newFields = {
                        ...fields,
                        gsm: parsedNumber.gsm,
                        countryCode: parsedNumber.countryCode,
                      };
                    } else {
                      newFields = {
                        ...fields,
                        gsm: "",
                        countryCode: "386",
                      };
                    }

                    if (updateLocation) {
                      updateExistingLocation(newFields);
                      toggleModal();
                    } else {
                      addLocation(newFields);
                      toggleModal();
                    }

                    setHasSubmitted(false);
                  }}
                >
                  {updateLocation ? t`Posodobi` : t`Shrani`}
                </Button>
              ),
              cancel: (
                <Button variant="outline" onClick={toggleModal}>
                  <Trans>Prekliči</Trans>
                </Button>
              ),
              delete: updateLocation ? (
                <Button
                  variant="white"
                  c={"red"}
                  onClick={() => {
                    alertModal(
                      t`Izbriši lokacijo`,
                      t`Ali ste prepričani, da želite izbrisati lokacijo?`,
                      { labelCancel: t`Prekliči`, labelConfirm: t`Potrdi` },
                      () => {
                        removeLocation(fields.locationId);
                        toggleModal();
                      },
                    );
                  }}
                >
                  <Trans>Izbriši lokacijo</Trans>
                </Button>
              ) : undefined,
            }}
          >
            <Flex direction={"column"} gap={20}>
              <TextInput
                placeholder={`${t`Lokacija`} Ljubljana`}
                label={t`Ime lokacije`}
                variant="filled"
                radius="md"
                withAsterisk
                value={fields.name}
                onChange={(e) =>
                  handleFieldChange(e.currentTarget.value, "name")
                }
                error={
                  hasSubmitted && (fields.name === "" || !fields.name)
                    ? t`Vnesite ime lokacije`
                    : null
                }
              />

              <TextInput
                placeholder={t`Ulica 13`}
                label={t`Ulica in hišna številka`}
                variant="filled"
                radius="md"
                withAsterisk
                value={fields.address}
                onChange={(e) =>
                  handleFieldChange(e.currentTarget.value, "address")
                }
                error={
                  hasSubmitted && (fields.address === "" || !fields.address)
                    ? t`Vnesite naslov`
                    : null
                }
              />

              <TextInput
                placeholder="Ljubljana"
                label={t`Kraj`}
                variant="filled"
                radius="md"
                withAsterisk
                value={fields.city}
                onChange={(e) =>
                  handleFieldChange(e.currentTarget.value, "city")
                }
                error={
                  hasSubmitted && (fields.city === "" || !fields.city)
                    ? t`Vnesite kraj`
                    : null
                }
              />

              <LimePhoneInputField
                label={t`Telefonska številka`}
                value={fields.gsm || ""}
                defaultCountry="SI"
                onChange={(e) => handleFieldChange(e, "gsm")}
                showError={
                  hasSubmitted && fields.gsm && !isValidPhoneNumber(fields.gsm)
                }
              />

              <div id="location-time-selectors">
                <TimeSpanRow
                  fromTimeValue={fields.startTime}
                  toTimeValue={fields.endTime}
                  onFromTimeChange={(e) => handleStartTimeChange(e)}
                  onToTimeChange={(e) => handleEndTimeChange(e)}
                  error={
                    hasSubmitted &&
                    (fields.startTime >= fields.endTime ||
                      !fields.startTime ||
                      !fields.endTime)
                      ? t`Izberite veljaven čas`
                      : null
                  }
                />
              </div>

              <Select
                data={countries.map((c) => ({
                  value: c.code,
                  label: c.name,
                  image: c.flag,
                }))}
                label={t`Država`}
                variant="filled"
                value={fields.country}
                onChange={(e) => handleFieldChange(e, "country")}
              />

              <Flex align="center" gap="md">
                <Text
                  c={"rgba(33, 37, 41)"}
                  variant={TextVariant.BodyEmphasized}
                >
                  <Trans>Privzeti jezik</Trans>
                </Text>
                <LocalePicker
                  style={{
                    zIndex: 1,
                  }}
                  value={fields.defaultLanguage}
                  onChange={(value) => {
                    handleFieldChange(value, "defaultLanguage");
                  }}
                  isOnForm={true}
                />
              </Flex>

              <Box>
                <Text
                  c={"rgba(33, 37, 41)"}
                  variant={TextVariant.BodyEmphasized}
                  mb={3}
                >
                  <Trans>Časovni pas</Trans>
                </Text>
                <TimezoneSelect
                  value={fields.timeZone}
                  onChange={(e) => handleFieldChange(e.value, "timeZone")}
                  placeholder={t`Izberite časovni pas`}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "var(--mantine-radius-default)",
                      background: "#f8f8f8",
                      border: "none",
                    }),
                    option: (provided) => ({
                      ...provided,
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }),
                  }}
                />
              </Box>

              <Select
                data={currencies.map((c) => ({ value: c, label: c }))}
                label={t`Valuta`}
                variant="filled"
                value={fields.paymentCurrency}
                onChange={(e) => handleFieldChange(e, "paymentCurrency")}
              />

              <Divider />

              <Switch
                labelPosition="left"
                w={"100%"}
                checked={fields.showHolidays}
                onChange={(e) => {
                  const checked = e.currentTarget.checked;
                  handleFieldChange(checked, "showHolidays");
                  if (checked === false) {
                    handleFieldChange(false, "disableOnHolidays");
                  }
                }}
                label={
                  <>
                    <Text variant={TextVariant.Body}>
                      <Trans>Prazniki in dela prosti dnevi</Trans>
                    </Text>
                    <Text variant={TextVariant.Caption} c={Color.SecondaryText}>
                      <Trans>Dela prosti dnevi</Trans>
                    </Text>
                  </>
                }
                styles={{
                  labelWrapper: {
                    width: "100%",
                  },
                }}
              />

              <Collapse in={fields.showHolidays}>
                <Switch
                  labelPosition="left"
                  w={"100%"}
                  checked={fields.disableOnHolidays}
                  onChange={(e) =>
                    handleFieldChange(
                      e.currentTarget.checked,
                      "disableOnHolidays",
                    )
                  }
                  label={
                    <>
                      <Text variant={TextVariant.Body}>
                        <Trans>Onemogoči naročanje</Trans>
                      </Text>
                      <Text
                        variant={TextVariant.Caption}
                        c={Color.SecondaryText}
                      >
                        <Trans>Dela prosti dnevi</Trans>
                      </Text>
                    </>
                  }
                  styles={{
                    labelWrapper: {
                      width: "100%",
                    },
                  }}
                />
              </Collapse>
            </Flex>
          </LimeDrawer>

          {locations.length === 0 && (
            <EmptyState
              title={t`Nimate nobene lokacije`}
              description={t`Začnite z dodajanjem prve lokacije.`}
              icon={<RiMapPin2Fill fill={"#6c7b86"} size={"2rem"} />}
            />
          )}
          <LocationTable
            modalOpened={modalOpened}
            updateStates={(e) => updateFields(e)}
            locations={locations}
          />
          {error && (
            <p>
              <Trans>Prišlo je do napake, prosimo osvežite stran</Trans>.
            </p>
          )}
        </Box>
      )}
    </>
  );
};

export default Locations;
